<template>
  <section>
    <b-row class="match-height">
      <b-col
        lg="4"
        md="6"
      >
        <b-card
          class="card-transaction"
          no-body
        >
          <b-card-header>
            <b-card-title>Selecciona Perfil</b-card-title>
          </b-card-header>

          <b-card-body>
            <div
              v-if="isBusyProfile"
              class="text-center text-primary my-2"
            >
              <b-spinner
                variant="primary"
                class="align-middle"
              />
              <strong class="ml-1">Cargando...</strong>
            </div>
            <div
              v-for="(profile, index) in profiles"
              :key="index"
              class="transaction-item"
            >
              <b-media no-body>
                <b-media-aside>
                  <b-avatar
                    rounded
                    size="42"
                    :variant="getClassColor(profile.idProfile)"
                  >
                    <feather-icon
                      size="18"
                      icon="UserIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="transaction-title">
                    {{ profile.name }}
                  </h6>
                  <small>Perfil</small>
                </b-media-body>
              </b-media>
              <b-button
                v-b-tooltip.hover.top="'Gestionar Permisos'"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                size="sm"
                @click="getModules(profile)"
              >
                <feather-icon icon="UnlockIcon" />
              </b-button>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        v-if="idProfileSel !== -1"
        lg="8"
      >
        <b-card
          class="card-transaction"
          no-body
        >
          <b-card-header>
            <b-card-title>Permisos para Perfil: {{ nameProfileSel }}</b-card-title>
          </b-card-header>
          <b-card-body>
            <app-collapse>
              <app-collapse-item
                v-for="option in modules"
                :key="option.value"
                is-visible
                :title="option.text"
              >
                <b-form-group>
                  <b-form-checkbox
                    v-for="subModule in option.subModules"
                    :key="subModule.idModule"
                    v-model="selected"
                    :value="subModule.idModule"
                    name="flavour-3a"
                  >
                    {{ subModule.name }}
                  </b-form-checkbox>
                </b-form-group>
              </app-collapse-item>
            </app-collapse>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="button"
              @click="saveForm"
            >
              Guardar
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar, BButton, BRow, BCol, BSpinner, VBTooltip, BFormCheckbox, BFormGroup } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { showError } from '@/helpers'

export default {
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BRow,
    BCol,
    BSpinner,
    BButton,
    AppCollapse,
    AppCollapseItem,
    BFormCheckbox,
    BFormGroup,
  },
  data() {
    return {
      classColors: ['success', 'primary', 'danger', 'warning', 'info', 'secondary'],
      profiles: [],
      modules: [],
      isBusyProfile: false,
      isBusyModule: false,
      showModules: true,
      showPerfiles: false,
      isGetPerfiles: false,
      sistemaSel: {},
      selected: [],
      initiallySelected: [],
      idProfileSel: -1,
      nameProfileSel: '',
    }
  },
  async mounted() {
    await this.getProfiles()
  },
  methods: {
    async saveForm() {
      const service = 'security/PERMISSION_CREATE'
      // Elementos seleccionados actualmente pero que estaban deseleccionados inicialmente
      const newSelected = this.selected.filter(id => !this.initiallySelected.includes(id))
      // Elementos que estaban seleccionados inicialmente pero ahora están deseleccionados
      const deselected = this.initiallySelected.filter(id => !this.selected.includes(id))

      // Guardar nuevos permisos (status: true)
      newSelected.forEach(modulex => {
        const formData = {
          idProfile: this.idProfileSel,
          idModule: modulex,
          status: true,
        }
        store.dispatch(service, formData)
          .then(response => {
            console.log('response >', response)
          })
          .catch(error => {
            this.$bvToast.toast(error, {
              title: 'Ha ocurrido un error',
              variant: 'danger',
              solid: true,
            })
          })
      })

      // Guardar permisos eliminados (status: false)
      deselected.forEach(modulex => {
        const formData = {
          idProfile: this.idProfileSel,
          idModule: modulex,
          status: false,
        }
        store.dispatch(service, formData)
          .then(response => {
            console.log('response >', response)
          })
          .catch(error => {
            this.$bvToast.toast(error, {
              title: 'Ha ocurrido un error',
              variant: 'danger',
              solid: true,
            })
          })
      })

      this.$bvToast.toast('Se ha actualizado los permisos del perfil.', {
        title: 'Operación Exitosa',
        variant: 'success',
        solid: true,
      })

      // Actualizamos `initiallySelected` para reflejar el nuevo estado
      this.initiallySelected = [...this.selected]
    },

    getModules(profile) {
      this.idProfileSel = profile.idProfile
      this.nameProfileSel = profile.name
      this.isBusyModule = true
      this.selected = []
      this.initiallySelected = []
      store
        .dispatch('security/MODULE_LIST', {
          idProfile: this.idProfileSel,
        })
        .then(response => {
          this.modules = response.map(modulex => {
            modulex.subModules.forEach(subModule => {
              if (subModule.permission) {
                this.selected.push(subModule.idModule)
              }
            })
            return {
              value: modulex.idModule,
              text: modulex.name,
              subModules: modulex.subModules,
            }
          })
          this.isBusyModule = false
        })
        .catch(error => {
          this.showError(error)
          this.isBusyModule = false
        })
      this.initiallySelected = this.selected
    },
    async getProfiles() {
      this.isBusyProfile = true
      await store
        .dispatch('security/PROFILE_FIND_ALL', {
          limit: 50,
          query: '',
          page: 1,
          sortBy: 'idProfile%7CDESC',
        })
        .then(response => {
          this.profiles = response.items
          this.isBusyProfile = false
        })
        .catch(error => {
          this.showError(error)
          this.isBusyProfile = false
        })
    },
    refetchDataS(response) {
      let message = 'El proceso se ha ejecutado satisfactoriamente.'
      if (response.message) {
        message = response.message
      }
      this.$bvToast.toast(message, {
        title: 'Operación Extitosa',
        variant: 'success',
        solid: true,
      })
      this.getProfiles()
    },
    showError,
    getClassColor(index) {
      return this.classColors[index - 1]
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
</style>
